<template>
    <seasonal-background>
        <div class="wrapper">
            <base-header type="gradient-success" class="base-header pt-md-7">
                <slot name="header"/>
            </base-header>

            <b-container fluid class="mt--6 mb-3">
                <slot/>
            </b-container>
        </div>

        <p class="footer-navigation small">
            Site web réalisé par
            <a href="https://julien-emmanuel.com" target="_blank">
                Julien EMMANUEL
                <font-awesome-icon icon="external-link-alt"/>
            </a> |
            Thème Argon par
            <a href="https://www.creative-tim.com" target="_blank">
                Creative Tim
                <font-awesome-icon icon="external-link-alt"/>
            </a> |
            <router-link :to="{name: 'cgu'}">
                Conditions générales d'utilisation et mentions légales
            </router-link>
        </p>
    </seasonal-background>
</template>

<script>
    const SeasonalBackground = () => import('@/components/SeasonalBackground');

    export default {
        name: "DashboardBodyTemplate",
        components: {SeasonalBackground}
    }
</script>

<style scoped>
    .base-header {
        padding-bottom: 10rem;
        padding-top: 10rem;
    }

    .wrapper {
        min-height: calc(100vh - 47px); /* Allows the footer to stay at the bottom of pages, even small ones */
        z-index: 1; /* Because seasonal falling things have z-index 0 */
    }

    p.footer-navigation {
        text-align: right;
        margin-right: 20px;
        margin-bottom: 5px;
        padding-top: 20px;
        z-index: 1; /* Because seasonal falling things have z-index 0 */
        /* relative positioning is only to allow z-index to work */
        position: relative;
    }
</style>
